import { InputBase, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react"; 
import { toast } from "react-toastify";

const Subscription = () => {
    const [ email , setEmail] = useState("");
    const [ err , showErr ] = useState(false);
    const [ errText, setErrText ] = useState("");

    const handleChange = (event) => {
        setEmail(event.target.value);
        setErrText("")
    }

    const handleSubmit = async () => {
        if(email != "") {
            let regex = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
            if(!regex.test(email))
                setErrText("Please enter a valid email");
            else {
                try {
                    const result: any = await axios.post( "https://app-server.dev-developer-portal.mysmitch.com/v1/email/add", 
                    {
                    "email": email,
                    "has_requested_beta_access": false
                    },
                    { 
                        headers: {
                        "Content-Type": "application/json"
                        }
                    })
                    toast.success(result.data.message);
                }
                catch (error) {
                    // Sentry.captureException(error);
                    toast.error("Something went wrong. Please try again later");
                    console.log("error", error.response);
                }
            }
        }
        else { 
            setErrText("Email Cannot be Empty");
        }   
    }

    return (
        <div className = "cls_subscriptionPage">
            <div className = "cls_subscriptionWrap">
                <div className = "cls_subsCont">
                    <div className = "cls_subsTitle">
                        Stay Tuned!
                    </div>
                    <div className = "cls_subDesc">
                        Subscribe to our newsletter for the latest news and events.
                    </div>
                </div>
                <div className = "cls_subsInpWrap">
                    <InputBase
                        value = {email}
                        onChange = {(event) => handleChange(event)}
                        className="cls_subsInp"
                        placeholder="Enter your email..."
                        inputProps={{ 'aria-label': 'Enter your email...' }}
                    />
                    {
                        errText !== "" && 
                        <div className = "cls_subsInpWrapErr">
                            {errText}
                        </div>
                    }
                </div>
                <div className = "cls_subsBtn" onClick = {handleSubmit}>
                    Subscribe
                </div>
            </div>
        </div>
    )
}

export default Subscription;
import React, { useEffect, useState } from "react"; 
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const HomePageStatic = () => {

    const [ isLoggedIn , setLogin ] = useState("false");

    useEffect(() => {
        setLogin(localStorage.getItem("isLoggedIn"))
    },[])

    return (
        <div className = "cls_HomePageWrapper">
            <div className = "cls_HomeDescCont">
                <div className = "cls_HomeBgWrap">
                    {/* <img
                        src={"../line-2.png"}
                        alt="SMITCH logo" height="100%" width="500" /> */}
                    <StaticImage className = "cls_HomeBgLine" src="../images/line-2.png" alt="Smitch" width={500} />
                    <div className = "cls_HomeDescWrap">
                        <div className = "cls_HomeDescBody">
                            <div className = "cls_HomeTitle">The Smitch Ecosystem</div>
                            <div className = "cls_HomeSubTitle">Welcome to the Smitch Family!</div>
                            <div className = "cls_HomeBody">
                                Join an open ecosystem of Smitch devices, appliances and Works with Smitch devices.Let's create the connected world of the future together.
                                <span>Sign up today!</span>
                            </div>
                            {
                                isLoggedIn !== "true" && 
                                <Link to="/signup"  style={{ textDecoration: 'none' }}>
                                    <div className = "cls_SignUpBtn">Sign Up</div>
                                </Link>
                            }
                        </div>
                        {/* <div className = "cls_HomeDescImg">
                            <img
                            src={"../atom.png"}
                            alt="SMITCH logo" height="100%" width="450" />
                        </div> */}
                    </div>
                </div>
                <div className = "cls_HomeBgWrap2">
                    <div className = "cls_HomeDescWrap2">
                        <div className = "cls_HomeBody">
                            The Smitch ecosystem enables you to build and integrate IoT devices, services, and automations on Smitch Cloud.
                        </div>
                        <div className = "cls_HomeImages">
                            <div className = "cls_HomeImgCont">
                                <img
                                src={"../cloud.png"}
                                alt="SMITCH logo" height="100%" width="450" />
                                <div className = "cls_HomeImgDesc">Enroll</div>
                            </div>
                            <div className = "cls_HomeImgCont">
                                <img
                                src={"../settings.png"}
                                alt="SMITCH logo" height="100%" width="450" />
                                <div className = "cls_HomeImgDesc">Build</div>
                            </div>
                            <div className = "cls_HomeImgCont">
                                <img
                                src={"../switch.png"}
                                alt="SMITCH logo" height="100%" width="450" />
                                <div className = "cls_HomeImgDesc">Test</div>
                            </div>  
                        </div>
                        <div className = "cls_HomeTitle">Developer Workspace</div>
                        <div className = "cls_HomeBody">Developer Workspace provides easy-to-use tools for integrating your IoT devices and Automations with the Smitch platform. Create and manage projects, team members and virtual devices.</div>
                    </div>
                    <img
                        src={"../lines.png"}
                        alt="SMITCH logo" width="300" />
                </div>
            </div>
        </div>
    )
}

export default HomePageStatic;
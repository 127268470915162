import * as React from "react";
import FooterLinks from "../components/FooterLinks";
import HomePageStatic from "../components/HomePageStatic";
import Layout from "../components/Layout";
import Subscription from "../components/Subscription";
import "./index.css";
import "./index-mob.css";
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  return (
    <Layout>
      <div className = "cls_HomePageWrapper">
        <div className = "contentWrapper">
          <div className = "imgWrapper">
            {/* <img
              src={"./dummy.png"}
              alt="SMITCH logo" /> */}
            <StaticImage src="../images/homepage.png" alt="Smitch" layout="fullWidth" loading = "eager"/>
          </div>
        </div>
        <HomePageStatic />
        <Subscription />
      </div>
      
    </Layout>
  )
}

export default IndexPage
